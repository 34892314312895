import {
  AssetGroupRef, RoleSubject, Schedule, ZoneRef,
} from '@x-guard/xgac-types/xgac';
import { httpClient } from '../utils/httpClient';
import { XGAC_REPORT_API_URL } from '../config';
import { getCurrentCustomer } from '../lib/currentCustomer';
import { ReportAuditLog } from '../lib/constants/customTypes';

const apiUrl = XGAC_REPORT_API_URL;
const getReports = async (body: any) => {

  const url = `${apiUrl}/data`;
  return httpClient(url, { method: 'POST' }, body);

};

export enum AuditOperations {
  create = 'create',
  read = 'read',
  update = 'update',
  delete = 'delete',
}

export const reportDataProvider = {

  getAuditReport: async (
    after: Date,
    direction: 'before' | 'after',
    max: number,
    subjects: RoleSubject[],
    actionFilter: AuditOperations[],
    exposeUsers: boolean,
    ids?: string[],
  ) => {

    const currentCustomer = getCurrentCustomer();
    if (!currentCustomer) {

      return null;

    }
    const reportRequestBody = (customerId: string) => {

      return {
        options: {
          timeSeries: null,
          config: {
            type: 'auditLog',
            subject: subjects,
            operation: actionFilter,
            exposeUsers,
            ids,
            pagination: {
              after: after.toISOString(),
              order: direction === 'before' ? 'desc' : 'asc',
              max,
            },
          },
          customer: {
            _id: customerId,
            _ref: 'Customer',
          },
        },
      };

    };

    const reportResult = await getReports(reportRequestBody(currentCustomer.value));
    return reportResult.json?.value?.result.sort((a: ReportAuditLog, b: ReportAuditLog) => {

      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();

    });

  },

  getUnhealthyBeacons: async (lastSeenThreshold: { type: string; value: number }) => {

    const currentCustomer = getCurrentCustomer();
    if (!currentCustomer) {

      return null;

    }
    const reportRequestBody = (customerId: string) => {

      return {
        options: {
          timeSeries: null,
          config: {
            type: 'unhealthyBeacons',
            lastSeenThreshold,
            timeSeries: {
              granularity: {
                type: 'day',
                value: 1,
              },
              timeframe: {
                type: 'week',
                value: 1,
              },
            },
          },
          customer: {
            _id: customerId,
            _ref: 'Customer',
          },
        },
        includeEmailAttachments: true,
      };

    };

    const reportResult = await getReports(reportRequestBody(currentCustomer.value));
    return reportResult.json;

  },

  getZoneOverview: async (
    includeGroups: AssetGroupRef[],
    zones: ZoneRef[],
    timeFrameAndGranularity: any,
    inactivityThreshold?: any,
    schedule?: Schedule,
    minOccupancy?: { assetCount: number; ratioThreshold: number },
  ) => {

    const currentCustomer = getCurrentCustomer();
    if (!currentCustomer) {

      return null;

    }

    const reportRequestBody = (customerId: string) => {

      return {
        options: {
          timeSeries: null,
          config: {
            assetAvailability: 'optimistic',
            assetGroups: includeGroups,
            zones,
            type: 'zoneOverview',
            inactivityThreshold: inactivityThreshold || undefined,
            schedule,
            minOccupancy,
            timeSeries: timeFrameAndGranularity,
          },
          customer: {
            _id: customerId,
            _ref: 'Customer',
          },
        },
      };

    };

    const reportResult = await getReports(reportRequestBody(currentCustomer.value));
    return reportResult.json;

  },

  getAssetHistory: async (assetId: string, timeFrame: { type: string; value: number }) => {

    const currentCustomer = getCurrentCustomer();
    if (!currentCustomer) {

      return null;

    }

    const reportRequestBody = () => {

      return {
        options: {
          timeSeries: null,
          config: {
            type: 'assetHistory',
            timeSeries: {
              granularity: {
                type: 'minute',
                value: 5,
              },
              timeframe: timeFrame,
            },
            asset: {
              _id: assetId,
              _ref: 'Asset',
            },
            aggregatorOptions: {
              includeSimple: true,
              includeRaw: false,
            },
          },
          customer: {
            _id: currentCustomer.value,
            _ref: 'Customer',
          },
        },
        includeEmailAttachments: true,
      };

    };

    const reportResult = await getReports(reportRequestBody());
    return reportResult.json;

  },

  getAttachmentForReport: (rawAttachments: any, document: Document) => {

    for (const rawAttachment of rawAttachments) {

      const mediaType = rawAttachment.contentType;
      const createdElement = document.createElement('a');
      createdElement.href = `data:${mediaType};base64,${rawAttachment.content}`;
      createdElement.download = rawAttachment.filename;
      createdElement.textContent = rawAttachment.description;
      createdElement.target = '_blank';
      createdElement.click();

    }

  },
};
